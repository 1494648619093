.contact {
  height: 100vh;
  position: relative;
}

.contact-bg {
  width: 20px;
  height: 100%;
  background-color: #e9967a;
  position: absolute;
}

.contact-wrapper {
  padding: 50px;
  display: flex;
}

.contact-left {
  flex: 1;
}

.contact-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-title {
  font-size: 60px;
  width: 80%;
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  font-weight: 300;
  width: 70%;
}

.contact-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.contact-description {
  font-weight: 200;
}

form {
  margin-top: 20px;
}

input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

button {
  border: none;
  padding: 15px;
  border-radius: 10px;
  background-color: #e9967a;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
}

input[type='submit'] {
  background-color: #0987f2;
  color: #fff;
  border: none;
  border-radius: 20px;
  width: 126px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
  margin: auto;
}

input[type='submit']:hover {
  transform: scale(1.05);
}

input[type='submit']:active {
  transform: translateY(2px);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 480px) {
  .contact-wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }

  .contact-title {
    font-size: 30px;
  }

  .contact-info-item {
    margin: 20px 0px;
    width: 100%;
  }

  .contact-description {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }
}
