.intro {
  display: flex;
  height: 100vh;
}

.intro-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-right {
  flex: 1;
  position: relative;
}

.intro-left-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.intro-greeting {
  font-size: 30px;
  font-weight: 300;
}

.intro-name {
  font-size: 60px;
  display: flex;
}

.intro-title {
  height: 50px;
  overflow: hidden;
}

.intro-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-icon {
  border-radius: 50px;
  transition: all 1s ease;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.intro-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #e9967a;
  display: flex;
  align-items: center;
}

.intro-scroll {
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 20px;
}

.intro-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

/* .intro-bg {
  clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background-color: #59b256;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
} */

@media screen and (max-width: 480px) {
  .intro {
    flex-direction: column;
  }

  .intro-greeting {
    display: none;
  }

  .intro-name {
    font-size: 70px;
  }

  .intro-left-wrapper {
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .intro-title-item {
    font-size: 25px;
    align-items: center;
    justify-content: center;
  }
  .intro-right {
    margin: 0 1rem;
  }
  .intro-desc {
    display: none;
  }
  .intro-img {
    height: 40vh;
    align-items: center;
    justify-content: center;
  }

  .social-icon {
    width: 65px;
    height: 73px;
    border-radius: 20px;
    margin: 0px 5px 15px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .intro {
    flex-direction: column;
  }

  .intro-left-wrapper {
    padding-top: 0;
  }

  .intro-greeting {
    display: none;
  }

  /* .intro-name {
    font-size: 70px;
    margin-bottom: 10px;
  } */

  .intro-title-item {
    font-size: 35px;
    height: 55px;
  }

  .intro-desc {
    position: relative;
    min-height: 60px;
    font-size: 25px;
    background-color: transparent;
  }
  @keyframes move {
    25% {
      transform: translateY(-60px);
    }
    50% {
      transform: translateY(-100px);
    }
    75% {
      transform: translateY(-205px);
    }
    100% {
      transform: translateY(-255px);
    }
  }
}
