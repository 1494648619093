.about {
  height: 100vh;
  display: flex;
  align-items: center;
}

.about-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.about-right {
  flex: 1;
}

.about-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.about-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-image:hover {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.about-award-image {
  width: 40%;
  height: 30vh;
  margin: 20px 10px;
  border-radius: 20px;
}

.about-title {
  font-weight: 400;
}

.about-sub {
  margin: 20px 0px;
  font-weight: 300;
}

.about-desc {
  font-weight: 300;
}

.about-award {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-award-texts {
  width: 70%;
}

.about-award-title {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .about {
    flex-direction: column;
    text-align: center;
  }
  .about-left {
    width: 100%;
  }

  .about-card {
    height: 30vh;
  }

  .about-card.bg,
  .about-award {
    display: none;
  }

  .about-right {
    padding: 20px;
  }
}

@media screen and (min-width: 485px) {
  .resume {
    display: none;
  }
}
