.projectList {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projectList-texts {
  width: 65%;
}

.projectList-title {
  font-size: 50px;
  font-weight: 600;
}

.projectList-desc {
  margin: 20px 0px;
}

.projectList-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .projectList {
    padding: 10px;
  }

  .projectList-title {
    font-size: 25px;
    font-weight: 500;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .projectList-texts {
    width: 100%;
  }

  .projectList-desc {
    display: none;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .projectList-texts {
    width: 95%;
  }

  .projectList-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 65px;
  }

  .projectList-desc {
    font-size: 1.9rem;
    font-weight: 300;
  }

  .projectList-list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
/* 
@media screen and (max-width: 1200px){

} */
