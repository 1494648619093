.testimonials {
  height: 100vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 50px 100px;
}

h1 {
  font-size: 50px;
}

.container {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 60%;
  height: 475px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -8px black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  margin: 0 20px;
  transition: all 1s ease;
}

.card:hover {
  transform: scale(1.1);
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.user {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 30px;
}

.center {
  padding: 5px;
  border-radius: 10px;
  background-color: rgb(250, 244, 245);
}

.bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

h3 {
  margin-bottom: 5px;
}

h4 {
  color: rgb(121, 115, 115);
}

@media screen and (max-width: 480px) {
  .testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px 75px;
  }

  h1 {
    font-size: 35px;
  }

  .container {
    flex-direction: column;
  }

  .card {
    width: 100%;
    font-size: 13px;
  }

  .center {
    padding: 15px;
  }
}

@media screen and (max-width: 768px) and (min-width: 48px) {
  .testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 50px 75px;
  }

  .container {
    flex-direction: column;
  }

  .card {
    padding: 30px 50px;
    margin: 20px;
    width: 80%;
    height: 75%;
  }
}
