.project {
  width: 30%;
  height: 30vh;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.project-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.project-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0.1rem;
  background-color: #fff;
}

.project-img {
  width: 100%;
  transition: all 10s ease;
}

/* .project:hover .project-img {
  transform: translateY(-100%);
} */

@media screen and (max-width: 480px) {
  .project {
    width: 40%;
    height: 20vh;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .project {
    width: 100%;
    height: 35vh;
  }
}
