.navbar {
  width: 100%;
  height: 70px;
  font-weight: 100;
  background-color: lightblue;
  opacity: 0.8;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1.5s ease;
}

.wrapper {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  /* opacity: 3; */
}

.right .hamburger {
  padding: 0.1rem 3rem;
  width: 32px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

span {
  width: 100%;
  height: 3px;
  background-color: #333;
  cursor: pointer;
  transform-origin: left;
  transition: all 2s ease;
}

.active {
  background-color: #e9976a;
  color: #fff;
  opacity: 0.9;
}

.active span:first-child {
  transform: rotate(35deg);
}
.active span:nth-child(2) {
  opacity: 0;
}

.active span:last-child {
  transform: rotate(-35deg);
}

li {
  list-style: none;
  display: inline-block;
  padding-right: 7px;
}

li:last-child {
  padding-right: 0;
}

a {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
  display: block;
  padding: 5px 8px;
  font-size: 0.8rem;
  background-color: transparent;
}

.navbar:active {
  background-color: #e9976a;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .left {
    display: none;
  }
}
